import React, { Fragment, useState, useEffect } from 'react';
import {
  Card, CardBody, CardHeader, Input, Table, Button, Modal,
  ModalHeader, ModalBody, ModalFooter, FormGroup, Label
} from 'reactstrap';
import { H5, H6 } from '../../AbstractElements';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  getStudentsByCourseId
} from '../../Services/courseService';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const CoursePage = () => {
  const { id } = useParams(); // Directly use useParams to get the id
  const [courseId, setCourseId] = useState(id); // Use the id directly
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [modal, setModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  useEffect(() => {
    const fetchCourses = async () => {
      if (hasFetched) return; // Prevent further calls if already fetched
      try {
        const fetchedCourses = await getStudentsByCourseId(courseId);
        setCourses(fetchedCourses);
        setFilteredCourses(fetchedCourses); // Show all courses initially
        toast.success('Courses fetched successfully'); // Show toast
        setHasFetched(true); // Set the flag to true after successful fetch
      } catch (error) {
        console.error('Error fetching courses:', error);
        toast.error('Error fetching courses');
      }
    };

    fetchCourses();
  }, [courseId, hasFetched]); // Depend on courseId and hasFetched

  useEffect(() => {
    filterCourses(); // Filter courses whenever searchQuery or courses change
  }, [searchQuery, courses]);

  const filterCourses = () => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = courses.filter(course =>
        course.name.toLowerCase().includes(lowercasedQuery) ||
        course.description.toLowerCase().includes(lowercasedQuery)
      );
      setFilteredCourses(filtered);
    } else {
      setFilteredCourses(courses);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCourse({ ...currentCourse, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(courses.map(student => ({
      Name: student.name,
      Email: student.email,
      Phone: student.phone,
      Gender: student.gender,
      Status: student.status,
      ReferralCode: student.referralCode,
      paymentId: student.paymentId,
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Course');
    XLSX.writeFile(wb, courseId + '.xlsx');
    toast.success("Course data Exported");
  };







  return (
    <Fragment>
      <Card style={{ width: '95%', margin: '0 auto', paddingRight: '5px' }} className="dash-35">
        <Card className='course-management'>
          <CardHeader className="card-no-border">
            <H5 attrH5={{ className: 'mb-0' }}>Course Detail</H5>
          </CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between mb-3">
              <Button color="primary" onClick={exportToExcel}>
                <FaPlus /> Export To Excel
              </Button>
            </div>

            <Table className="table-bordernone" responsive>
              <thead>
                <tr>
                  <th><H5>Name</H5></th>
                  <th><H5>Email</H5></th>
                  <th><H5>Phone</H5></th>
                  <th><H5>Gender</H5></th>
                  <th><H5>Status</H5></th>
                  <th><H5>Trasaction Id</H5></th>
                  <th><H5>Referal Code</H5></th>
                  <th><H5>Course Status</H5></th>
                </tr>
              </thead>
              <tbody>
                {filteredCourses.map((user) => (
                  <tr key={user._id}>

                    <td><H6>{user.name}</H6></td>
                    <td><H6>{user.email}</H6></td>
                    <td><H6>{user.phone}</H6></td>
                    <td><H6>{user.gender}</H6></td>
                    <td><H6>{user.status}</H6></td>
                    <td><H6>{user?.paymentId}</H6></td>
                    <td><H6>{user?.referralCode}</H6></td>
                    <td><H6>{user.courseStatus}</H6></td>

                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Card>




    </Fragment>
  );
};

export default CoursePage;
